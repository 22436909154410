/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import {
  Header,
  ClientButton,
  Container,
  ContainerHeader,
  Form,
  SendButton,
} from "./styles";
import Logo from "../../assets/logo_.png";
import api from "../../services/api";
import { login } from "../../services/auth";
import StorkNetworkLogo from "../../assets/storknetwork.png";
export default class Contact extends Component {
  constructor() {
    super();
    this.state = {
      lateralMenu: false,
      prevScrollpos: window.pageYOffset,
      name: "",
      email: "",
      message: "",
      cpf: "",
      password: "",
      error: null,
      mailSent: "",
      wait: false,
      portal: false,
      captcha: false,
      success: null,
      response: "",
    };
  }

  render() {
    const handlePortal = async () => {
      const { portal } = this.state;
      await this.setState({
        portal: !portal,
      });
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      this.setState({ wait: true });

      if (!this.state.captcha) {
        return toast.error("Resposta de Captcha Inválida");
      }

      if (isTrue()) {
        const { portal, email, name, message, password, cpf } = this.state;

        this.setState({ wait: false });
        this.setState({ response: "Aguarde" });

        if (portal) {
          try {
            const response = await api.post("/users", {
              name,
              cpf,
              email,
              password,
            });
            toast.success("Sua conta foi criada, enviando mensagem...");
            const session = await api.post("/session", {
              cpf,
              password,
            });
            login(session.data.token);

            if (response.status === 200) {
              toast.success("Sua conta foi criada, enviando mensagem...");
              const msg = await api.post(`/contact/1`, {
                content: message,
              });

              if (msg.status === 200) {
                this.setState({ error: null, success: "Mensagem Enviada" });
              }
              setTimeout(function () {
                toast("Redirecionando para o painel do cliente");
              }, 2000);
              setTimeout(function () {
                window.location.href = "https://app.storkdev.com";
              }, 3000);
            }
          } catch (e) {
            this.setState({ error: e.response.data.error });
            toast.error(
              "Falha ao criar sua conta, enviando mensagem sem autenticação"
            );
            sendMessage(name, email, message);
            toast.success("Mensagem enviada com sucesso!");
          }
        } else {
          sendMessage(name, email, message);
        }

        this.setState({ wait: false });
      }
    };

    const sendMessage = async (name, email, content) => {
      try {
        this.setState({ error: "Enviando..." });
        const response = await api.post("/free/contact", {
          email,
          name,
          content,
        });

        this.setState({ error: null, success: "Mensagem Enviada" });

        toast.success("Mensagem enviada com sucesso!");
      } catch (err) {
        this.setState({
          error:
            "Falha ao enviar mensagem, entre em contato por todomundo@storkdev.com",
        });
      }
    };
    const LoadingContainer = () => {
      return <div>Enviando...</div>;
    };

    const { name, email, message, cpf, password } = this.state;

    const isTrue = () => {
      const primary = name.length > 3 && email.length > 6 && message.length > 1;

      if (!this.state.portal) {
        return primary;
      }

      const secundary = cpf.length === 11 && password.length >= 6;

      return primary && secundary;
    };

    return (
      <>
        <Header>
          <div>
            {" "}
            <img src={Logo} className="logo" alt="Logo" />{" "}
          </div>
          <ClientButton>AJUDA </ClientButton>
        </Header>

        <Container>
          <ContainerHeader>
            <img src={StorkNetworkLogo} className="stork-network-logo" alt="stork-network-logo" />
            <h2>EM BREVE</h2>
          </ContainerHeader>

          
        </Container>
      </>
    );
  }
}
