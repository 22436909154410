import styled from "styled-components";

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;

  .logo {
    width: 110px;
    height: 50px;
  }


`;

export const ClientButton = styled.button`
  width: 180px;
  height: 50px;
  background: var(--accendent-color);
  border-radius: 8px;
  border: none;
  color: var(--main-text-color);
  cursor: pointer;
  font-weight: bolder;
`;

export const Container = styled.div`
  margin-top: 64px;
  display: flex;
  background-color: var(--main-container-bg-color);
  border-radius: 8px;
  padding: 32px;
  flex-direction: column;

  @media only screen and (max-width: 900px) {
    margin-bottom: 64px;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;

  h1,
  h2 {
    font-size: 16px;
    text-align: center;
  }

  h2 {
    color: var(--accendent-color);
  }
  .stork-network-logo {
    width: 220px;
    height: 160px;
    margin-bottom: 2rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: row;
  margin-top: 64px;
  justify-content: space-around;

  .left {
    display: flex;
    flex-direction: column;
  }

  .right {
    display: flex;
    flex-direction: column;
  }

  textarea {
    width: 350px;
    background: #070707;
    border-radius: 8px;
    border: solid 1px #444444;
    color: var(--main-text-color);
    padding: 16px;
    margin-bottom: 16px;
  }

  input,
  textarea {
    transition: all 0.3s ease;
  }

  input {
    width: 350px;
    height: 50px;
    background: #070707;
    border-radius: 8px;
    border: solid 1px #444444;
    color: var(--main-text-color);
    padding-left: 16px;
    margin-bottom: 32px;
  }

  .portal {
    width: auto;
    height: auto;
  }

  .optional {
    flex-direction: column;
  }

  .portalCheckBox {
    display: flex;
    flex-direction: row;

    label {
      margin-left: 8px;
    }
  }

  input:focus-within,
  textarea:focus-within {
    border: solid 1px var(--sub-color);
  }

  @media only screen and (max-width: 900px) {
    flex-direction: column;

    input,
    textarea {
      width: 100%;
    }
  }
`;

export const SendButton = styled.button`
  margin-top: 32px;
  width: 180px;
  height: 50px;
  background: #27ae60;
  border-radius: 8px;
  border: none;
  color: var(--main-text-color);
  cursor: pointer;

  text-decoration: ${(props) =>
    props.disabled ? "line-through !important;" : "none"};
  cursor: ${(props) =>
    props.disabled ? "not-allowed !important;" : "pointer"};
  display: ${(props) => (props.show ? "none !important;" : "initial")};
`;
