import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

    :root {
    --main-bg-color: #0e0e10;
    --main-container-bg-color: #18181b;
    --accendent-color: #9147ff;
    --accendent-color-dark: #5f359d;
    --main-text-color: #ffffff;
    --sub-text-color: #989898;
    --gradient-overlay-start: #9147ff;
    --gradient-overlay-end: transparent;
    --sub-color: #27ae60;
    }

    *{
        @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        overflow-x: hidden;
        font-family: 'Roboto', sans-serif;
    }

    body {
        padding-left: 25vw;
        padding-right: 25vw;
        background: var(--main-bg-color);
        color: var(--main-text-color);

        @media only screen and (max-width: 900px) {
         padding-left: 16px;
        padding-right: 16px;
        }
    }
    `;
